import { Center, Heading, Stack, Text } from "@chakra-ui/layout";
import React from "react";

export const App = () => {
  return (
    <Center>
      <Stack maxW={"600px"} p={5}>
        <Heading>Gratulerer med konfirmasjonen!</Heading>
        <Heading as="h2" size="l">
          ..og kondolerer, du er snart voksen.
        </Heading>
        <Text as="i" fontWeight="semibold" pt="5">
          Dette er{" "}
          <a href="https://rolsen33.com">
            <code>rolsen33.com</code>
          </a>
          , din egen nettside!
        </Text>
        <Text>
          Den er kanskje ikke den mest fancy nettsiden akkurat nå, men tanken er
          at vi kan jobbe med den sammen for å lære litt om nettsider og
          programmering 🤓
        </Text>
        <Text>
          Nå som du snart er voksen, er det også på sin plass at du får et par
          ordentlige voksen-underbukser, som er designet for aktive og raske
          bois ⚡️
        </Text>
      </Stack>
    </Center>
  );
};
